<template>
<div class="app-container with-content-offset-top with-content-offset-bottom">

  <h1 class="app-reading-title with-aside">Аддони</h1>

  <v-container fluid pa-0>
    <v-row>
      <v-col v-for="{ name, desc, tags, links } in [...appSettings.addons].sort((a, b) => a.name.localeCompare(b.name))" :key="name" cols="12" sm="6" lg="4">
        <div class="card">
          <ul class="tags">
            <li v-for="tag in tags" :key="tag">
              <v-chip v-if="tag == 'PVP'" label small color="red lighten-2" text-color="white">PVP</v-chip>
              <v-chip v-else-if="tag == 'PVE'" label small color="orange accent-1">PVE</v-chip>
              <v-chip v-else label small color="blue-grey lighten-3">{{ tag }}</v-chip>
            </li>
          </ul>
          <div class="name">{{ name }}</div>
          <div class="desc" v-if="desc">{{ desc }}</div>
          <ul class="links" v-if="links && links.length > 0">
            <li v-for="link in links" :key="link">
              <a :href="link" target="_blank">
                <v-icon v-if="link.match('twitch.tv')" x-large>mdi-twitch</v-icon>
                <v-icon v-else-if="link.match('curseforge.com')" x-large>mdi-fire</v-icon>
                <v-icon v-else-if="link.match('youtube.com')" x-large>mdi-youtube</v-icon>
                <v-icon v-else x-large>mdi-link-variant</v-icon>
              </a>
            </li>
          </ul>
        </div>
      </v-col>
    </v-row>
  </v-container>

</div>
</template>

<script>
export default {
  created: function () {
    this.setPageMetadata('Аддони')
  }
}
</script>

<style scoped>

.card {
  font: var(--font-text);
  font-size: 18px;
  margin-bottom: 3em;
}

.card .tags {
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  list-style: none;
}

.card .tags li {
  margin-bottom: 1em;
}

.card .tags li:not(:first-child) {
  margin-left: .5em;
}

.card .name {
  font: var(--font-text-bold-extra);
  font-size: 175%;
}

.card .desc {
  margin-top: 1em;
  line-height: 1.8;
}

.card .links {
  margin-top: 1em;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  list-style: none;
}

.card .links li:not(:first-child) {
  margin-left: .5em;
}

.card .links a {
  text-decoration: none;
}

@media (min-width: 600px) {
  .card .tags {
    min-height: 40px;
  }
}

@media (min-width: 1264px) {
  .row {
    margin: 0 -40px;
  }

  .row > div {
    padding: 0 40px;
  }
}

@media (max-width: 1263px) {
  .card {
    font-size: 16px;
  }

  .card .name {
    font-size: 150%;
  }
}

</style>
